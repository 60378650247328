import { useState } from "react";
import { gql, useQuery } from "@apollo/client";

import EditLocationTwoToneIcon from '@mui/icons-material/EditLocationTwoTone';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';

import { Container, Button, Tooltip, Popover, ButtonGroup, Chip, Stack } from "@mui/material";

import Book from "./Book";

import "./Home.css"

const CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      id
      location {
        latitude
        longitude
      }
      booksAround {
        id
        title
        cover
        author
        shortDescription
        description
        tags
        copies(preloaded: true) {
          id
          distance
        }
      }
    }
  }
`

export default function Home() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  const { loading, data } = useQuery(CURRENT_USER);

  if(loading) return(null);

  console.log(data);

  const { currentUser: { location, booksAround } } = data;

  if(booksAround.length > 0) {
    return (
      <div>
        {/* <div style={{ paddingBottom: 18 }}>
          <Stack direction="row" spacing={1}>
            <Chip label="Все" color="primary" size="small" />
            <Chip label="Бизнес" variant="outlined" size="small" />
            <Chip label="Маркетинг" variant="outlined" size="small" />
            <Chip label="Личное развитие" variant="outlined" size="small" />
            <Chip label="Творчество" variant="outlined" size="small" />
            <Chip label="Комиксы" variant="outlined" size="small" />
            <Chip label="Детство" variant="outlined" size="small" />
            <Chip label="Креатив" variant="outlined" size="small" />
            <Chip label="Кругозор" variant="outlined" size="small" />
            <Chip label="ЗОЖ" variant="outlined" size="small" />
            <Chip label="Научпоп" variant="outlined" size="small" />
            <Chip label="Проза" variant="outlined" size="small" />
            <Chip label="Культура" variant="outlined" size="small" />
            <Chip label="Психология" variant="outlined" size="small" />
          </Stack>
        </div> */}
        <div className="books-grid">
          {
            booksAround.map(book => <Book key={book.id} book={book} />)
          }
        </div>
      </div>
    )
  } else {
    return(
      <Container maxWidth="sm" style={{ height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <span style={{ fontSize: 40 }}>😏</span>
        <p><b>Похоже ты первопроходец</b></p>
        <div style={{ textAlign: "center", color: "gray" }}>
          <span>В радиусе 15 километров вокруг выбранной тобой</span>
          &nbsp;
          <span
            aria-describedby={id}
            onClick={handleClick}
            style={{ cursor: "pointer", textDecoration: "underline" }}
          >
            локации
          </span>
          &nbsp;
          <span>не найдено ни одной книги.</span>
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", padding: 20 }}>
            <ButtonGroup variant="outlined" aria-label="">
              <Tooltip title="Посмотреть локацию на карте">
                <Button
                  variant="outlined"
                  onClick={ () => window.open(`https://www.google.com/maps/place/${location.latitude},${location.longitude}/@${location.latitude},${location.longitude},15z`, "_blank") }
                >
                  <TravelExploreTwoToneIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Сменить локацию">
                <Button
                  variant="outlined"
                  onClick={ () => window.open(`tg://resolve?domain=${process.env.REACT_APP_BOT_USERNAME}&start=set_location`) }
                >
                  <EditLocationTwoToneIcon />
                </Button>
              </Tooltip>
            </ButtonGroup>
          </div>
        </Popover>
        <br />
        <p style={{ textAlign: "center"}}>
          Будь первым!
        </p>
        {/* <br /> */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ width: 168 }}></div>
          <Tooltip title="Просто отправь фотографии через Telegram-бота">
            <Button
              variant="outlined"
              onClick={ () => window.open(`tg://resolve?domain=${process.env.REACT_APP_BOT_USERNAME}&start=share`) }
            >
              Добавь свои книги
            </Button>
          </Tooltip>
          <div style={{ width: 7 }}></div>
          <Tooltip title="и соседей 😆">
            <div>
              <Button
                disabled={true}
                variant="text"
                size="small"
              >
                И пригласи друзей
              </Button>
            </div>
          </Tooltip>
        </div>
      </Container>
    );
  }

}
