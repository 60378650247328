import { useState, useContext, createContext } from "react";
import { useQuery, useMutation, useApolloClient, gql } from "@apollo/client";
import ls from "local-storage";
// import { useNavigate } from "react-router-dom";

const LOGOUT = gql`
  mutation Logout($token: String!) {
    logout(token: $token) {
      token
    }
  }
`

const CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      id
    }
  }
`

const LOGIN = gql`
  mutation Login($key: String!) {
    login(key: $key) {
      token
      currentUser {
        id
      }
      redirectTo
    }
  }
`

const authContext = createContext();

export function AuthProvider({ children }) {
  const auth = useAuthProvider();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useAuthProvider() {
  // const navigate = useNavigate();
  const [user, setUser] = useState(undefined);
  // const history = useHistory();

  const { loading: userLoading } = useQuery(CURRENT_USER, {
    fetchPolicy: "network-only",
    onCompleted: ({currentUser}) => {
      setUser(currentUser)
    }
  })

  const client = useApolloClient()

  const [runLogoutMutation] = useMutation(LOGOUT, {
    onCompleted: () => {
      ls.remove("token")
      client.clearStore()
      setUser(null)
    }
  })

  const logout = (token = null) => {
    token = (token === null) ? ls.get("token") : token
    runLogoutMutation({variables: {
      token: token
    }})
  }

  const [loginUser] = useMutation(LOGIN, {
    onCompleted: ({login: {token, currentUser, redirectTo}}) => {
      if(token !== null) {
        ls.set("token", token)
        // NOTE: https://www.apollographql.com/docs/react/networking/authentication/#reset-store-on-logout
        client.clearStore()
  
        setUser(currentUser)
        if(redirectTo) {
          window.location.replace(redirectTo);
          // history.replace(redirectTo)
        }
      } else {
        // navigate("/")
        window.location.replace("/invalid_login_link");
        // console.log("Try login again");
      }
    }
  })

  const login = (key) => {
    loginUser({variables: {
      key: key
    }});
  }

  return {
    logout,
    login,
    user,
    userLoading
  };
}
