import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Button, Container, Tooltip, Box } from "@mui/material";
// import ReactGA from "react-ga4";
import Home from "./Home";
import { useAuth } from "./useAuth";
import "./App.css";

import logo from "./logo.png";

import Avatar from '@mui/material/Avatar';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Logout from '@mui/icons-material/Logout';

export default function App() {
  const { logout } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container fixed style={{ display: 'flex', flexDirection: 'column', height: "100vh" }}>
      <div className="header" style={{ borderBottom: "solid 1px rgba(0, 0, 0, 0.12)", boxSizing: "border-box", paddingTop: 10, paddingBottom: 10, marginBottom: 20 }}>
        <div style={{ display: "flex", alignItems: "center"}}>
          <div style={{ boxSizing: "border-box", paddingRight: 10 }} >
            <img src={logo} style={{ width: 35 }} />
          </div>          
          <Box sx={{ display: { xs: "none", sm: "block" } }} style={{ color: "#474445" }}>
            <div style={{ fontWeight: "bold" }}>
              sharers.club
            </div>
            <Box sx={{ display: { xs: "none", md: "block" } }} style={{ fontSize: 14}}>
              Экспериментируем с идеей совместного использования вещей
            </Box>
          </Box>
        </div>
        <div className="menu">
          <Tooltip title="Просто отправь фотографию через Telegram-бота">
            <Button
              variant="text"
              size="small"
              onClick={ () => {
                window.open(`tg://resolve?domain=${process.env.REACT_APP_BOT_USERNAME}&start=share`);
                // ReactGA.event("button_click", {button: "Add book"});
              }}
            >
              Добавить книгу
            </Button>
          </Tooltip>
          <div>
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <Avatar sx={{ width: 32, height: 32 }} >
                <PersonOutlineIcon />
              </Avatar>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem onClick={() => {handleClose(); logout();}}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Выйти
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>

      <div style={{ flex: 1 }}>
        <Routes>
          <Route path="*" element={ <Home /> } />
        </Routes>
      </div>
    </Container>
  );
}
