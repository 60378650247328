import { ApolloClient, InMemoryCache, ApolloLink, HttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import ls from "local-storage";

export const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_HOST}/graphql`
});

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export const authLink = new ApolloLink((operation, forward) => {
  const token = ls.get("token")
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      "Authorization": token ? `Bearer ${token}` : "",
    }
  }));
  return forward(operation);
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([
    errorLink,
    authLink,
    httpLink
  ])
});
