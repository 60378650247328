import { Button, Tooltip, IconButton } from "@mui/material";
import GoogleIcon from '@mui/icons-material/Google';
import ReactGA from "react-ga4";

export default function Book({book}) {
  const distance = book.copies.reduce((min, current) => {
    return current.distance < min ? current.distance : min;
  }, Infinity);

  return(
    <div className="book" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: 212}}>
      <div>
        <div className="cover" style={{ width: 212, height: 310, display: "flex", alignItems: "flex-end", justifyContent: "center"}}>
          {
            book.cover &&
            <img src={book.cover.replace("sharers.club", "d3gyr3ryjqlw0e.cloudfront.net")} style={{ maxWidth: 212, maxHeight: 310 }} />
          }
          {
            !book.cover &&
            <div style={{ background: `hsla(${~~(360 * (book.title.charCodeAt(0) * book.title.charCodeAt(book.title.length-1)) * 0.1 )}, 70%,  82%, 0.8)`, width: 212, height: 310, boxSizing: "border-box", padding: 10, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center"}}>
              {book.title}
              {
                book.author &&
                <>
                  <br />
                  <br />
                  <small>{book.author}</small>
                </>
              }
            </div>
          }
        </div>
        <div style={{ fontSize: 14, boxSizing: "border-box", paddingTop: 9 }}>{book.shortDescription}</div>
      </div>

      <div
        style={{ display: "flex", justifyContent: "space-between", boxSizing: "border-box", paddingTop: 4, paddingBottom: 0, alignItems: "center" }}
      >
        <Tooltip title="Расстояние до книги">
          <span style={{ fontSize: 12, color: "#b4b4b4", cursor: "default" }}>
            {
              distance < 100 &&
              <span>
                {distance} м
              </span>
            }
            {
              distance >= 100 &&
              <span>
                {(distance / 1000).toFixed(1)} км
              </span>
            }
          </span>
        </Tooltip>
        <Tooltip title="Загуглить книгу">
          <IconButton
            aria-label="Google"
            style={{ marginRight: 10 }}
            onClick={() => {
              const searchQuery = encodeURIComponent(`${book.author}. ${book.title}`);
              window.open(`https://www.google.com/search?q=${searchQuery}`)
            }}
          >
            <GoogleIcon
              style={{
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.12)"
              }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="Напиши нам в Telegram">
          <Button
            onClick={ () => {
              window.open("tg://resolve?domain=sharers_club");
              // ReactGA.event("button_click", {button: "Request book", book_id: book.id});
            }}
            size="small"
            variant="outlined"
            style={{ fontSize: 9 }}
          >
            Запросить
          </Button>
        </Tooltip>
      </div>
    </div>
  )
}
