import { Container, Button, Tooltip } from "@mui/material";

import logo from "./logo.png";

export default function Landing() {
  return (
    <Container maxWidth="md" style={{ display: 'flex', flexDirection: 'column', height: "100vh" }}>
      <div className="content" style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: 'center' }}>
        {/* backgroundColor: "rgba(0, 0, 0, 0.05)" */}
        {/* backgroundColor: "rgba(0, 0, 0, 0.05)" */}
        <div style={{ display: 'flex', flexDirection: "column", width: "100%", backgroundColor: "rgba(0, 0, 0, 0.04)", alignItems: "center", boxSizing: "border-box", padding: 40  }}>
          <img src={logo} alt="logo" style={{ width: 90 }} />
          <div style={{ fontWeight: "bold", marginTop: 10 }}>
            sharers.club
          </div>
          <div style={{ marginTop: 10, marginBottom: 20 }}>
            Экспериментируем с идеей совместного использования вещей
          </div>
          <div>
            <Tooltip title="Через Telegram-бот">
              <Button
                variant="contained"
                onClick={ () => window.open(`tg://resolve?domain=${process.env.REACT_APP_BOT_USERNAME}&start=registration`) }
              >
                Регистрация
              </Button>
            </Tooltip>
            &nbsp;
            &nbsp;
            <Tooltip title="Через Telegram-бот">
              <Button
                variant="contained"
                onClick={ () => window.open(`tg://resolve?domain=${process.env.REACT_APP_BOT_USERNAME}&start=login`) }
              >
                Войти
              </Button>
            </Tooltip>
          </div>
        </div>

        <div style={{ color: "#474445", marginTop: 30, marginBottom: 10, textAlign: "center" }}>
          <b>Почему это круто:</b>
        </div>

        <div style={{ color: "#474445", display: 'flex', alignItems: 'center', marginBottom: 20 }}>
          <div style={{ boxSizing: "border-box", paddingRight: 20 }}>
            <div className="emoji-background">🤝</div>
          </div>
          <p>
            <b>Социально.</b> В последние годы много людей вынуждены были переехать. Кому-то пришлось оставить часть своих вещей, кто-то все еще живет на чемоданах и не может себе позволить обрастать новыми вещами. Кроме того, прежние социальные связи разорваны, а выстраивать новые - сложный процесс. Обе эти проблемы можно решить, начав обмениваться вещами и общаться вокруг этого процесса.
          </p>
        </div>

        <div style={{ color: "#474445", display: 'flex', alignItems: 'center', marginBottom: 20 }}>
          <div style={{ boxSizing: "border-box", paddingRight: 20 }}>
            <div className="emoji-background">🌍</div>
          </div>
          <p>
            <b>Экологично.</b> Так как мы не только уменьшаем потребление, но и каждый, кто берет вещь на время, делает небольшой донейшен (5-10% от стоимости вещи) в пользу одной из общественных организаций, заботящихся о нашей планете.
          </p>
        </div>

        <div style={{ color: "#474445", display: 'flex', alignItems: 'center', marginBottom: 30 }}>
          <div style={{ boxSizing: "border-box", paddingRight: 20 }}>
            <div className="emoji-background">💰</div>
          </div>
          <p><b>Экономично.</b> Есть вещи, которыми мы пользуемся редко и большую часть времени просто храним их на полке. А что если взять такую вещь на время у человека, живущего неподалеку? Так ты сэкономишь не только деньги, но и время на выбор и покупку.</p>
        </div>

        <div style={{marginBottom: 20, color: "#474445", fontSize: 14 }}>
          <p>
            Проект sharers.club это по сути эксперимент, в рамках которого мы проверяем, готовы ли люди делиться своими вещами. Решение давать ли вещь каждый принимает сам на основании открытого профиля в социальных сетях человека и статистики предыдущего взаимодействия с ним в рамках нашего проекта.
          </p>
        </div>
      </div>

      <div className="footer" style={{ display: 'flex', alignItems: 'center', color: "#474445", fontSize: 12, borderTop: "solid 1px rgba(0, 0, 0, 0.12)", boxSizing: "border-box", paddingTop: 10, paddingBottom: 10, marginTop: 10  }}>
        © {new Date().getFullYear()} sharers.club
        &nbsp;
        •
        &nbsp;
        <span>Связь с командой проекта через <a href="tg://resolve?domain=sharers_club" style={{ textDecoration: "none", color: "#474445" }}>Telegram</a></span>
      </div>
    </Container>
  )
}
