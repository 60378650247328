import { useEffect } from "react";
import { useParams,  createBrowserRouter, RouterProvider } from "react-router-dom";
import ReactGA from "react-ga4";
import { useAuth } from "./useAuth";
import App from "./App";
import Landing from "./Landing";
import InvalidLoginLink from "./InvalidLoginLink";

import './App.css';

import CircularProgress from "@mui/material/CircularProgress";

function LoadingScreen() {
  return(
    <div className="center">
      <CircularProgress />
    </div>
  )
}

function Guard() {
  const { accessKeyToken } = useParams();
  const { login } = useAuth();

  useEffect(() => {
    login(accessKeyToken);
    // eslint-disable-next-line
  }, [])


  return(<LoadingScreen />);
}

export default function Root() {
  const { user, userLoading } = useAuth();

  if(userLoading) return <LoadingScreen />

  if(user) {
    ReactGA.set({ user_id: user.id });
  }

  const router = createBrowserRouter([
    {
      path: "/-/:accessKeyToken",
      element: <Guard />,
    },
    {
      path: "/invalid_login_link",
      element: <InvalidLoginLink />,
    },
    {
      path: "*",
      element: user ? <App /> : <Landing />,
    },
  ]);

  return (
    <RouterProvider router={router} />
  );
}
