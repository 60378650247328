import { Container, Button, Tooltip } from "@mui/material";

import logo from "./logo.png";

export default function Landing() {
  return (
    <Container fixed style={{ display: 'flex', flexDirection: 'column', height: "100vh" }}>
      <div className="header" style={{ borderBottom: "solid 1px rgba(0, 0, 0, 0.12)", boxSizing: "border-box", paddingTop: 10, paddingBottom: 10, marginBottom: 10 }}>
        <div style={{ display: "flex", alignItems: "center"}}>
          <div style={{ boxSizing: "border-box", paddingRight: 10 }} >
            <img src={logo} alt="logo" style={{ width: 35 }} />
          </div>
          <div style={{ color: "#474445" }}>
            <div style={{ fontWeight: "bold" }}>
              sharers.club
            </div>
            <div style={{ fontSize: 14}}>
              Экспериментируем с идеей совместного использования вещей
            </div>
          </div>
        </div>

        <div style={{  }} >
          <Tooltip title="Через Telegram-бот">
            <Button
              variant="outlined"
              onClick={ () => window.open(`tg://resolve?domain=${process.env.REACT_APP_BOT_USERNAME}&start=login`) }
            >
              Войти
            </Button>
          </Tooltip>
        </div>
      
      </div>

      <div className="content" style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ fontSize: 40 }}>😔</div>
          <p>
            Что-то пошло не так, попробуйте войти еще раз.
          </p>
      </div>

      <div className="footer" style={{ display: 'flex', alignItems: 'center', color: "#474445", fontSize: 12, borderTop: "solid 1px rgba(0, 0, 0, 0.12)", boxSizing: "border-box", paddingTop: 10, paddingBottom: 10, marginTop: 10  }}>
        © {new Date().getFullYear()} sharers.club
        &nbsp;
        •
        &nbsp;
        <span>Связь с командой проекта через <a href="tg://resolve?domain=sharers_club" style={{ textDecoration: "none", color: "#474445" }}>Telegram</a></span>
      </div>
    </Container>
  )
}
